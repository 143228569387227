import React from "react";
import styled from "styled-components";
// Assets
import { useState } from "react";
import './styles/styles.css';
import { Modal } from "react-bootstrap";

export default function ServiceBox({title, img, info, moreInfo }) {

  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
 
  return (
   
   <div className="card text-center">
      <div className="overflow">
        <img src={img} alt="a wallpaper" className="card-img-top" />
      </div>
      <div>
        <h4 className="card-title" style={{color:"#2D2D45"}}>{title}</h4>
        <ul className="ul-info">
          {info.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
        <Button
          className="button-seemore"
          onClick={handleShow}
        >
          see more
        </Button>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title >{title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {moreInfo.map((info, i) => (
              <div key={i}>
                <h5>{info.title}:</h5>
                <p>{info.description}</p>
              </div>
            ))}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
}

const Wrapper = styled.div`
  width: 100%;
`;

const Card = styled.div`
  width: calc(33.33% - 10px); 
  margin-bottom: 20px;

  @media (max-width: 860px) {
    width: calc(50% - 10px); 
  }
`;

const TitleStyle = styled.h2`
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
  padding: 40px 0;
  @media (max-width: 860px) {
    padding: 20px 0;
  }
`;

const Button = styled.button`
border: 1px solid ${(props) => (props.border ? "#707070" : "#C4C4CF")};
background-color: ${(props) => (props.border ? "transparent" : "#C4C4CF")};
border-radius: 5px;
width: 100%;
padding: 15px;
font-weight:600;
outline: none;
color: ${(props) => (props.border ? "#707070" : "#050508")};
:hover {
  background-color: ${(props) => (props.border ? "transparent" : "#2D2D45")};
  border: 1px solid #2D2D45;
  color: ${(props) => (props.border ? "#162b4e" : "#fff")};
}
`;
