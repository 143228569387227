import React from "react";
import styled from "styled-components";
// Components
import ServiceBox from "../Elements/ServiceBox";


export default function Services() {

  const tasks = [
    {
      id: 1,
      img: "./trabajo3.jpg",
      title: "Process Optimization and Consulting",
      info: ["Process Optimization", "Consulting Services"],
      moreInfo: [
        {
          title:"Process Optimization",
          description:"Analyzes and enhances industrial processes to improve efficiency, reduce costs, and enhance overall performance. Utilizes various tools and methodologies to identify and eliminate bottlenecks and inefficiencies."
        },
        {
          title:"Consulting Services",
          description:"Offers expert advice on various engineering and technical matters. Assists clients in making informed decisions based on industry best practices and expertise.",
        },
      ]
    },
    {
      id: 2,
      img: "./trabajo4.jpg",
      title: "Compliance and Sustainability",
      info: ["Regulatory Compliance", "Environmental Impact Assessment", "Energy Management"],
      moreInfo: [
        {
          title:"Regulatory Compliance",
          description: "Ensures that projects adhere to relevant laws, regulations, and industry standards. Helps clients navigate complex regulatory environments to avoid legal issues and penalties."
        },
        {
          title:"Environmental Impact Assessment",
          description: "Evaluates the potential environmental effects of a project. Aims to minimize negative impacts and implement measures to enhance sustainability."
        },
        {
          title:"Energy Management",
          description: "Focuses on optimizing energy consumption within industrial processes. Aims to reduce energy costs and minimize the environmental impact of operations."
        },
        
      ]
    },
    {
      id: 3,
      img: "./trabajo5.jpg",
      title: "Operations and Quality",
      info: ["Construction Management", "Training and Education", "Asset Management"],
      moreInfo: [
        {
          title:"Construction Management",
          description:"Involves overseeing the construction phase of a project, coordinating contractors, managing timelines, and ensuring quality control. Aims to optimize construction processes to deliver projects on time and within budget."
        },
        {
          title:"Training and Education",
          description:"Provides training programs and educational services to enhance the skills and knowledge of personnel within an organization. Ensures that staff are well-equipped to handle the demands of complex engineering projects."
        },
        {
          title:"Asset Management",
          description:"Involves the strategic management of an organization's assets throughout their lifecycle. Includes planning, acquisition, operation, maintenance, and disposal of assets."
        },
        {
          title:"Supply Chain Optimization",
          description:"Analyzes and improves the efficiency of supply chain processes. Enhances coordination between suppliers, manufacturers, and distributors to streamline operations."
        },
        {
          title:"Quality Assurance and Control",
          description:"Implements measures to ensure the quality of products, processes, and services. Involves the establishment of quality standards, testing protocols, and continuous improvement initiatives"
        }
        
      ]
      // "Supply Chain Optimization", "Quality Assurance and Control"
    },
    {
      id: 4,
      img: "./trabajo1.jpg",
      title: "Project Planning and Management",
      info: ["Feasibility Studies", "Project Management", "Financing Solutions for International Trade Purchases"],
      moreInfo: [
        {
          title:"Feasibility Studies",
          description:"Involves a comprehensive analysis to determine the economic, technical, and operational viability of a project before it proceeds to implementation. Identifies potential challenges, risks, and opportunities associated with the project."
        },
        {
          title:"Project Management",
          description:"Encompasses planning, organizing, and overseeing all aspects of a project, ensuring it stays on schedule, within budget, and meets its objectives. Involves coordinating various teams, managing resources, and mitigating risks to achieve project success."
        },
        {
          title:"Financing Solutions for International Trade Purchases",
          description:"Providing financial solutions for international trade transactions."
        },
        
      ]
    },
    {
      id: 5,
      img: "./trabajo2.jpg",
      title: "Engineering Design and Analysis",
      info:["Engineering Design", "Risk Analysis", "Technical Audits"],
      moreInfo: [
        {
          title:"Engineering Design",
          description:"Creating detailed plans and specifications for engineering projects. Covers conceptualization, drafting, and final design phases, considering factors such as safety, functionality, and cost-effectiveness."
        },
        {
          title:"Risk Analysis",
          description:"Identifies and assesses potential risks that could impact the success of a project. Involves the development of risk mitigation strategies to minimize the likelihood and impact of adverse events."
        },
        {
          title:"Technical Audits",
          description:"Involves a systematic examination of technical aspects of a project or system to assess its performance, compliance with standards, and overall effectiveness. Identifies areas for improvement and optimization."
        },       
      ]
    },
  ];

  return (
    <Wrapper id="services">
      <div className="whiteBg" style={{ padding: "60px 0" }}>
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">What do we do</h1>
          </HeaderInfo>
          <div className="container d-flex justify-content-center align-items-center h-100">
            <div className="row d-flex justify-content-center">
              {tasks.map(({ id, title, info, img, moreInfo  }) => (
                <div className="col-md-4 container-card" key={id}>
                  <ServiceBox img={img} title={title} info={info} moreInfo={moreInfo} />
                </div>
              ))}
            </div>
          </div>
        </div>
      
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;  
`;

const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;
