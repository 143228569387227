import styled from "styled-components";
// Components
import FullButton from "../Buttons/FullButton";

export default function Header() {
 
  return (
    <Wrapper id="home" className="container flexSpaceCenter">
      <RightSide>
        <ImageWrapper>
          <Img className="radius8" src="./homepage1.jpg" alt="office" />
        </ImageWrapper>
      </RightSide>
      <LeftSide className="flexCenter">
        <DivContainer>
          <h1 className="extraBold font60Header">
            We are your best strategic partner, with comprehensive solutions for
            the global industry.
          </h1>
          
        </DivContainer>
      </LeftSide>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  max-width: 100%;
  width: 100%;
  min-height: 840px;
  position: relative;
  @media (max-width: 820px) {
    min-height: 800px;
  }
  @media (max-width: 800px) {
    min-height: 750px;
  }
  @media (max-width: 700px) {
    min-height: 600px;
  }

  @media (max-width: 520px) {
    min-height: 500px;
  }
  @media (max-width: 400px) {
    min-height: 470px;
  }

  @media (max-width: 370px) {
    min-height: 450px;
  }
  @media (max-width: 330px) {
    min-height: 420px;
  }
`;

const LeftSide = styled.div`
  width: 50%;
  height: 100%;
  position: absolute;
  top: 45%;
  left: 42%;
  transform: translate(-50%, -50%);
  z-index: 10;

  @media (max-width: 960px) {
    width: 100%;
    order: 1;
    position: absolute;
    top: 50%;
    left: 50%;
    font-size:20px;

  }
`;
const TitleHeader = styled.div`
  color:"#fff";
  @media (max-width: 960px) {
    width: 100%;
    font-size:20px;
    color:"#fff";
  }
`;
const RightSide = styled.div`
  width: 100%;
  height: 100%;
`;

const HeaderP = styled.div`
  max-width: 470px;
  padding: 15px 0 50px 0;
  line-height: 1.5rem;
  margin-top: 10px;
  margin-bottom: -20px;
  @media (max-width: 960px) {
    padding: 15px 0 50px 0;
    text-align: center;
    max-width: 100%;
  }
`;

const BtnWrapper = styled.div`
  max-width: 190px;

  @media (max-width: 960px) {
    margin: 0 auto;
  }
`;

const ImageWrapper = styled.div`
  position: relative;
  z-index: 1;
  width: 100%;
  display: flex;
  justify-content: center;
  max-height: 700px;
  height: 100%;
`;

const Img = styled.img`
  width: 100%;
  height: auto;
  border-radius: 8px;
  filter: brightness(50%);
  @media (max-width: 560px) {
    width: 100%;
    heigth:100%
  }
`;


const DivContainer = styled.div`
@media (max-width: 1000px) {
  width: 800px;
}

@media (max-width: 840px) {
  width: 800px;
}

@media (max-width: 830px) {
  width: 700px;
}

@media (max-width: 750px) {
  width: 550px;
}

@media (max-width: 600px) {
  width: 500px;
}

@media (max-width: 550px) {
  width: 450px;
}

@media (max-width: 490px) {
  width: 400px;
}

@media (max-width: 450px) {
  width: 380px;
}

@media (max-width: 425px) {
  width: 350px;
}

@media (max-width: 390px) {
  width: 300px;
}

@media (max-width: 350px) {
  width: 270px;
}

`;