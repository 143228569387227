import React from "react";
import styled from "styled-components";

export default function FullButton({ title, action, border }) {
  return (
    <Wrapper
      className="animate pointer radius8"
      onClick={action ? () => action() : null}
      border={border}
    >
      {title}
    </Wrapper>
  );
}

const Wrapper = styled.button`
  border: 1px solid ${(props) => (props.border ? "#707070" : "#C4C4CF")};
  background-color: ${(props) => (props.border ? "transparent" : "#C4C4CF")};
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  width: 100%;
  padding: 15px;
  font-weight:600;
  outline: none;
  color: ${(props) => (props.border ? "#707070" : "#050508")};
  :hover {
    background-color: ${(props) => (props.border ? "transparent" : "#2D2D45")};
    border: 1px solid #2D2D45;
    color: ${(props) => (props.border ? "#162b4e" : "#fff")};
  }
`;

