import React from "react";
import styled from "styled-components";
import { Link } from "react-scroll";


export default function Contact() {

  const getCurrentYear = () => {
    return new Date().getFullYear();
  }

  return (
    <Wrapper>
      <div className="darkBg">
        <div className="container">
          <InnerWrapper className="flexSpaceCenter" style={{ padding: "30px 0" }}>
            <Link className="flexCenter animate pointer" to="home" smooth={true} offset={-80}>
              <img src="./Vanie.JPG" alt="logo" style={{width:'120px', marginBottom:"20px"}}/>
              <h1 className="font15 extraBold whiteColor" style={{ marginLeft: "15px", color:'#2D2D45' }}>
                VANIEX
              </h1>
            </Link>
            <StyleP className="whiteColor font13">
              © {getCurrentYear()} - <span style={{color:'#2D2D45', fontWeight:'600'}}>Vaniex </span> All Right Reserved
            </StyleP>
            
            {/* <div style={{alignItems: 'center', display: 'flex', justifyContent: 'center', flexDirection: 'column'}}>
            <StyleP className="whiteColor font13">
               Síguenos
            </StyleP>
            <div style={{display:'flex'}}>
              <img src="./instagram.png" alt="instagram" style={{width:'20px', margin:'5px', cursor:'pointer'}}/>
              <img src="./twitter.svg" alt="twitter" style={{width:'20px', margin:'5px', cursor:'pointer'}}/>
            </div>
            </div> */}
          </InnerWrapper>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
`;
const InnerWrapper = styled.div`
  @media (max-width: 550px) {
    flex-direction: column;
  }
`;
const StyleP = styled.p`
  @media (max-width: 550px) {
    margin: 20px 0;
  }
`;