import React, { useRef, useState, useEffect } from 'react';
import styled from "styled-components";
import emailjs from '@emailjs/browser';
import Swal from 'sweetalert2';
import './Css/styles.css';

export default function Contact() {
  
  const form = useRef();
  const [nameError, setNameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [messageError, setMessageError] = useState('');
  const [nameSuccess, setNameSuccess] = useState(false);
  const [emailSuccess, setEmailSuccess] = useState(false);
  const [messageSuccess, setMessageSuccess] = useState(false);

  const validateName = (value) => {
    if (value.trim() === '') {
      setNameError('');
      setNameSuccess(false);
    } else if (value.length < 3 || value.length > 16) {
      setNameError('Please enter a valid name (3 to 16 characters).');
      setNameSuccess(false);
    } else {
      setNameError('');
      setNameSuccess(true);
    }
  };
  
  const validateEmail = (value) => {
    if (value.trim() === '') {
      setEmailError('');
      setEmailSuccess(false);
    } else if (!value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
      setEmailError('Please enter a valid email address.');
      setEmailSuccess(false);
    } else {
      setEmailError('');
      setEmailSuccess(true);
    }
  };
  
  const validateMessage = (value) => {
    if (value.trim() === '') {
      setMessageError('');
      setMessageSuccess(false);
    } else if (value.length < 10 || value.length > 150) {
      setMessageError('Please enter a valid message (10 to 150 characters).');
      setMessageSuccess(false);
    } else {
      setMessageError('');
      setMessageSuccess(true);
    }
  };

  const handleNameChange = (e) => {
    const value = e.target.value;
    validateName(value);
  };

  const handleEmailChange = (e) => {
    const value = e.target.value;
    validateEmail(value);
  };

  const handleMessageChange = (e) => {
    const value = e.target.value;
    validateMessage(value);
  };

  const sendEmail = (e) => {
    e.preventDefault();

    // Validar los campos antes de enviar
    if (!nameSuccess || !emailSuccess || !messageSuccess) {
      return;
    }
   
    const nameInput = form?.current?.user_name;
    
    emailjs.sendForm('service_zyqkv9k', 'template_sfn3ib8', form.current, 'CSp1f2zdk8tJCfaY1')
      .then((result) => {
          console.log(result.text);
          Swal.fire({
            title: "Your message was sent successfully.",
            text: "you will have a response soon",
            icon: "success",
            customClass: {
              confirmButton: "sweetalert-button", 
            },
            buttonsStyling: false,
          })
          form.current.reset();
      }, (error) => {
          console.log(error.text);
          Swal.fire({
            title: "There was a mistake.",
            text: "Please contact us directly by email or cell phone number",
            icon: "error"
          });  
          form.current.reset();
      });
  };
  
  
  return (
    <Wrapper id="contact">
      <div className="lightBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Contact</h1>
            <p className="font13">
            For any questions, please complete the following contact form:
            </p>
          </HeaderInfo>
          <DivContact style={{ paddingBottom: "30px", display:'flex' }}>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">

            <Form ref={form} onSubmit={sendEmail}>
       
        <input
          type="text"
          id="name"
          placeholder="name"
          name="user_name"
          className={`font20 extraBold ${nameSuccess ? 'success' : ''}`}
          onChange={handleNameChange}
        />
        {nameError && <ErrorText>{nameError}</ErrorText>}

        <input
          type="email"
          id="email"
          placeholder="email"
          name="user_email"
          className={`font20 extraBold ${emailSuccess ? 'success' : ''}`}
          onChange={handleEmailChange}
        />
        {emailError && <ErrorText>{emailError}</ErrorText>}

        <textarea
          rows="4" cols="50"
          type="text" id="message"
          name="message"
          placeholder="message"
          className={`font20 extraBold ${messageSuccess ? 'success' : ''}`}
          onChange={handleMessageChange}
        />
        {messageError && <ErrorText>{messageError}</ErrorText>}

        <SumbitWrapper className="flex">
          <ButtonInput
            type="submit"
            value="Send"
            className="pointer animate radius8"
            style={{ maxWidth: "220px", fontWeight: 700, fontSize: '17px', backgroundColor: '#162b4e' }}
          />
        </SumbitWrapper>
      </Form>
      
            </div>
            <div style={{padding: '70px 0 30px 0'}}>
              <h4>CORPORATE OFFICES</h4>
              <p> 7300 Edmonds ST, Burnaby, Canada</p>
              
              <h4>CONTACT</h4>
              <p>+1 (778) 522-3107</p>
              <p>hello@vaniex.com</p>
            </div>
          </DivContact>

          <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2606.049206090992!2d-122.95062879999999!3d49.218593899999995!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x548677ceaadf0a63%3A0xd0b1b7d830265475!2s7300%20Edmonds%20St%2C%20Burnaby%2C%20BC%20V3N%200G8%2C%20Canad%C3%A1!5e0!3m2!1ses-419!2sar!4v1701300269718!5m2!1ses-419!2sar"
                width="100%"            
                height="450"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const HeaderInfo = styled.div`
  padding: 70px 0 30px 0;
  display: flex;
  flex-direction: column;
  text-align: center;
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Form = styled.form`
  padding: 70px 0 30px 0;
  input,
  textarea {
    width: 100%;
    background-color: transparent;
    border: 0px;
    outline: none;
    box-shadow: none;
    border-bottom: 1px solid #707070;
   
    margin-bottom: 30px;
  }
  textarea {
    min-height: 100px;
  }
  @media (max-width: 860px) {
    padding: 30px 0;
  }
`;
const ButtonInput = styled.input`
  border: 1px solid #162b4e;
  width: 100%;
  padding: 15px;
  outline: none;
  color: #fff;
  :hover {
    background-color: #162b4e;
    border: 1px solid #162b4e;
    color: #fff;
  }
  @media (max-width: 991px) {
    margin: 0 auto;
  }
`;

const SumbitWrapper = styled.div`
  display: flex;
  justify-content: center;
  @media (max-width: 991px) {
    width: 100%;
    margin-bottom: 50px;
  }
`;

const DivContact = styled.div`
  display: flex;
  justify-content: space-around;
  @media (max-width: 985px) {
    flex-direction: column;
    align-items: center;
  }
`;

const ErrorText = styled.p`
  color: red;
  margin-bottom: 20px;
  margin-top: -20px;
  font-size: 14px;
`;
