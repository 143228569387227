import React from "react";
import Slider from "react-slick";
import styled from "styled-components";
// Assets

export default function ClientSlider() {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 2,
    arrows: true,
    responsive: [

      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 3,
          arrows: true,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          arrows: false
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          arrows: false
        },
      },
    ],
  };
  return (
    <Wrapper id="Alliances" className="lightBg">
      <div style={{ padding: "50px 0" }}>
        <div className="container">
          <HeaderInfo>
              <h1 className="font40 extraBold">Our Alliances</h1>
          </HeaderInfo>
          <SliderContainer>
          <Slider {...settings}>
            <LogoWrapper className="">
              <ImgStyle src="./logo1.png" alt="client logo" />
            </LogoWrapper>
            <LogoWrapper className="">
              <ImgStyle src="./logo2.png" alt="client logo" />
            </LogoWrapper>
            <LogoWrapper className="">
              <ImgStyle src="./logo3.png" alt="client logo" />
            </LogoWrapper>
            <LogoWrapper className="">
              <ImgStyle src="./logo6.jpg" alt="client logo" />
            </LogoWrapper>
            <LogoWrapper className="">
              <ImgStyle src="./logo5.png" alt="client logo" />
            </LogoWrapper>
          </Slider> 
          </SliderContainer> 
        </div>
      </div>  
    </Wrapper>
  );
}

const LogoWrapper = styled.div`
  width: 100%;
  height: 220px;
 
  cursor: pointer;
  :focus-visible {
    outline: none;
    border: 0px;
  }
`;
const ImgStyle = styled.img`
  max-width: 100%;
  border-radius: 4%;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  max-height: 100%;
  width: 150px;
  height: 150px;
  @media (max-width: 575px) {
    width: 100px;
    height: 100px;
  }
  @media (max-width: 350px) {
    width: 80px;
    height: 80px;
  }
`;

const HeaderInfo = styled.div`
  margin-bottom: 30px;
  @media (max-width: 860px) {
    text-align: center;
  }
`;

const Wrapper = styled.section`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  background: #C4C4CF;
`;

const SliderContainer = styled.div`
  width: 100%;
  max-width: 900px; 
  margin: 0 auto;
  @media (max-width: 700px) {
    max-width: 650px; 
  }

  @media (max-width: 600px) {
    max-width: 500px; 
  }

  @media (max-width: 575px) {
    max-width: 500px; 
  }

  @media (max-width: 520px) {
    max-width: 450px; 
  }


  @media (max-width: 500px) {
    max-width: 400px; 
  }

  @media (max-width: 475px) {
    max-width: 320px; 
  }

  @media (max-width: 350px) {
    max-width: 300px; 
  }

  @media (max-width: 300px) {
    max-width: 250px; 
  }

  .slick-prev,
  .slick-next {
    position: absolute;
    top: 35%;
    
  }

  .slick-prev {
    left: -20px; /* Ajusta la posición izquierda según tus necesidades */
  }

  .slick-next {
    right: -10px; /* Ajusta la posición derecha según tus necesidades */
  }
`;